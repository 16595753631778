import {useMemo} from 'react'
import {useStatus} from 'src/app/common/queries/getStatus'
import {useContentItemType} from 'src/app/pages/Configuration/Pythia/queries/getType'
import {ISearchInputBlockItem} from './interfaces/ISearchInputBlock'
import styles from './sass/SearchInputBlock.module.scss'

const IncidentListItem = ({
  id,
  subject,
  content,
  activeIncident,
  prevIncidentIndex,
  index,
  onItemClick,
  ...rest
}: {
  id: string
  subject: string
  content: string
  activeIncident: ISearchInputBlockItem | null | undefined
  prevIncidentIndex: number | undefined
  index: number
  onItemClick: (item: ISearchInputBlockItem, index: number) => void
  [key: string]: any
}) => {
  const status = useStatus(rest.statusId)
  const type = useContentItemType(rest.typeId)
  const submenuTitle = useMemo(
    () => (type.data?.name ? `${type.data?.name ?? ''} / ${status.data?.submenuTitle ?? ''}` : ''),
    [type, status]
  )

  return (
    <li
      key={id}
      className={`
    ${styles.incident} 
    ${id === activeIncident?.id ? styles.active : ''}
    ${index === prevIncidentIndex ? styles.prevActiveItem : ''}
    `}
      onClick={() =>
        onItemClick(
          {
            id,
            subject,
            content,
            ...rest,
          },
          index
        )
      }
      role='presentation'
    >
      <span className={styles.titleContainer}>
        <h4 className={styles.title}>{subject}</h4>
        {submenuTitle && <pre>{submenuTitle}</pre>}
      </span>
      <p className={styles.description}>{content}</p>
    </li>
  )
}

export default IncidentListItem
