import axios from 'axios'

// eslint-disable-next-line import/prefer-default-export
export const lexiApi = axios.create()

lexiApi.interceptors.response.use((response) => {
  if (typeof response.data === 'object') {
    response.data = JSON.stringify(response.data)
  }
  return response
})

lexiApi.interceptors.request.use(
  (config) => {
    const storedToken = localStorage.getItem('kt-auth-react-v')
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const {api_token} = storedToken && JSON.parse(storedToken)

    if (api_token) {
      config!.headers!.Authorization = `Bearer ${api_token}`
    }
    return config
  },
  (error) => Promise.reject(error)
)
