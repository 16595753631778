import { Avatar } from 'primereact/avatar'
import { TabMenu, TabMenuTabChangeEvent } from 'primereact/tabmenu'

import { useMemo, useState } from 'react'
import { AiRequestsList } from './AiActionsList/AiRequestsList'
import avatar from 'src/app/common/assets/images/avatar1.png'
import { PromptRequest } from './PromptRequest/PromptRequest'

const items = [
  {label: 'Prompt', icon: 'pi pi-comment'},
  {label: 'AI Requests', icon: 'pi pi-comments'},
]

interface Props {
  statusId?: string
  onPrompt?: (prompt: string) => void
  onAction?: (actionId: string) => void
}

export const AiActionsSidebarView = ({statusId, onPrompt, onAction}: Props) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const viewMap: {[key: number]: JSX.Element} = useMemo(
    () => ({
      0: <PromptRequest onPrompt={onPrompt} />,
      1: <AiRequestsList onAction={onAction} statusId={statusId} />,
    }),
    []
  )

  const handleTabChange = (e: TabMenuTabChangeEvent) => {
    setActiveIndex(e.index)
  }

  return (
    <div style={{padding: '2rem'}}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{display: 'flex'}}>
          <div style={{margin: '4px 20px 0px 0px'}}>
            <Avatar image={avatar} shape='circle' size='xlarge' />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
            }}
          >
            <h3 style={{paddingLeft: '16.250px'}}>AI Menu</h3>
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: '2rem',
          width: '100%',
        }}
      >
        <TabMenu model={items} activeIndex={activeIndex} onTabChange={handleTabChange} />
        <div>{viewMap[activeIndex]}</div>
      </div>
    </div>
  )
}
