import {Notification} from 'src/app/common/hooks/useNotifications/useNotifications'

interface ResponseParams<T> {
  response: T
  addNotification: (notification: Notification) => void
  success?: string
  error?: string
}

interface ErrorParams {
  error?: string
  addNotification: (notification: Notification) => void
}

export const handleResponse = <T>({
  response,
  addNotification,
  success,
  error,
}: ResponseParams<T>) => {
  if (response && typeof response === 'object' && 'error' in response) {
    addNotification({
      sticky: true,
      closable: true,
      severity: 'error',
      summary: 'Something went wrong',
      detail: error ?? 'An error occurred, please try again.',
    })
  } else {
    addNotification({
      sticky: true,
      closable: true,
      severity: 'success',
      summary: 'Success',
      detail: success ?? 'Operation completed successfully.',
    })
  }
}

export const handleError = ({error, addNotification}: ErrorParams): void => {
  console.log(error)
  addNotification({
    sticky: true,
    closable: true,
    severity: 'error',
    summary: 'Error',
    detail: error || 'An unexpected error occurred.',
  })
}
