/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

const Footer: FC = () => {
  // const {classes} = useLayout()
  return (
    <>
      <div
        className={'footer py-4 d-flex flex-lg-column'}
        id='kt_footer'
        style={{paddingLeft: 92, textAlign: 'center'}}
      >
        {/*begin::Container*/}
        {/* <div className={clsx(classes.footerContainer, 'd-flex flex-column flex-md-row flex-stack')}> */}
        {/*begin::Copyright*/}
        <div className='text-dark order-2 order-md-1'>
          <p>
            Artificial Intelligence (AI) can occasionally make errors. Please verify all critical
            information independently.
          </p>
          <p>
            Refer to the accepted{' '}
            <a href='https://www.agedcareai.com.au/disclaimer' target='_blank'>
              Disclaimer
            </a>{' '}
            &{' '}
            <a href='https://www.agedcareai.com.au/user-license' target='_blank'>
              T&Cs
            </a>{' '}
            for further details. Powered by{' '}
            <a href='https://www.agedcareai.com.au/' target='_blank'>
              AgedCareAI.
            </a>
          </p>
        </div>
      </div>
      <div style={{bottom: 2, right: 2, position: 'fixed'}}>Beta Version 0.2.83</div>
    </>
  )
}

export {Footer}
