import type {KeyboardEvent, ReactElement} from 'react'
import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {TEXTAREA_INITIAL_HEIGHT} from '../../../../constants/chat'
import useDebounce from '../../../../hooks/useDebounce'
import {useAppSelector} from '../../../../store/hooks/useApp'
import categoriesSelector from '../../../../store/slices/categories/selectors'
import {useFetchSuggestionsQuery} from '../../../../store/slices/suggestions/apis/suggestions'
import Button from '../Button/Button'
import type {ITextField} from './interfaces/ITextField'

import {ReactComponent as Arrow} from '../../../../assets/icons/chat/up_arrow.svg'

import styles from './sass/TextField.module.scss'
import {
  AutoComplete,
  AutoCompleteChangeEvent,
  AutoCompleteCompleteEvent,
} from 'primereact/autocomplete'
import {Chip} from 'primereact/chip'

export default function TextField({
  searchTerm,
  setSearchTerm,
  handleAddMessage,
  isMessageLoading,
  files,
  setFiles,
}: ITextField): ReactElement {
  const {t} = useTranslation()
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const debouncedSearchTerm = useDebounce(searchTerm.trim())
  const {subCategory} = useAppSelector(categoriesSelector)
  const [overlayVisible, setOverlayVisible] = useState(false)

  const {data} = useFetchSuggestionsQuery({
    subCategory: subCategory?.id || '',
    search: debouncedSearchTerm,
    pageNumber: 1,
    pageSize: 10,
  })
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    if (searchTerm && data) {
      const values = data.data.map((x) => x.text)
      setSuggestions(values)
    } else {
      setSuggestions([])
    }
  }, [data, searchTerm])

  useEffect(() => {
    console.log(files)
    console.log(isDragging)

    if (files.length > 0) {
      console.log(files)
    }
  }, [files, isDragging])

  const onHandleSubmit = async () => {
    const element = document.getElementById('message-0')
    await handleAddMessage()

    if (textareaRef.current) {
      textareaRef.current.style.height = TEXTAREA_INITIAL_HEIGHT
    }

    if (element) {
      const container = element.parentElement
      if (container) {
        container.scrollTop = 0
      }
    }
  }

  const onHandleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (overlayVisible) {
      return
    }

    if (e.key === 'Enter' && !e.shiftKey) {
      if (!isMessageLoading) {
        e.preventDefault()
        onHandleSubmit().catch(() => {})
      }
    }

    if (e.key === 'Tab' && suggestions) {
      e.preventDefault()
      setSearchTerm(suggestions[0])
    }
  }

  const onHandleChange = (e: AutoCompleteChangeEvent) => {
    setSearchTerm(e.value)
  }

  const onHandleComplete = (e: AutoCompleteCompleteEvent) => {
    setSearchTerm(e.query)
  }

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
    const droppedFiles = e.dataTransfer.files
    setFiles([...files, ...Array.from(droppedFiles)])
  }
  const draggingStyle = isDragging
    ? {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      }
    : undefined
  const handleRemove = (file: File) => {
    setFiles(files.filter((x) => x !== file))
  }
  return (
    <div className={styles.searchInputBlock}>
      <div className={styles.searchInput}>
        {isDragging && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '18px',
              borderRadius: '8px', // Match the border radius of the container
              pointerEvents: 'none', // Allow interactions with the input field below the overlay
            }}
          >
            Drop the file here
          </div>
        )}
        <div
          className={styles.inputField}
          onDragEnter={handleDragEnter}
          onDragOver={(e) => e.preventDefault()} // Prevent default behavior for drag events
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          style={{position: 'relative'}}
        >
          <AutoComplete
            value={searchTerm}
            style={draggingStyle}
            onChange={onHandleChange}
            className={styles.autoComplete}
            onKeyDown={onHandleKeyDown}
            aria-label='Type your message'
            suggestions={suggestions}
            placeholder={t('chat.typeYourMessage')}
            completeMethod={onHandleComplete}
            onShow={() => setOverlayVisible(true)}
            onHide={() => setOverlayVisible(false)}
          />
        </div>
        <Button
          icon={<Arrow className={styles.arrow} />}
          onClick={onHandleSubmit}
          buttonStyle={styles.sendButton}
          disabled={!searchTerm && isMessageLoading}
        />
      </div>
      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          marginTop: '0.5rem',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        {files.map((file, index) => (
          <Chip key={index} label={file.name} removable onRemove={() => handleRemove(file)} />
        ))}
      </div>
    </div>
  )
}
