import {ReactElement, useEffect, useState} from 'react'
import book from '../../../../common/assets/icons/whiteSidebar/admins.svg'
import {ButtonVariant, ButtonWidthVariant} from '../../../../common/constants/enams'
import {formatAndSanitizeContent} from '../../../../common/helpers/formatAndSanitizeContent'
import useDebounce from '../../../../common/hooks/useDebounce'
import DotsLoader from '../../../../components/DotsLoader/DotsLoader'
import ResizableSidebar from '../../../../components/sidebar'
import Button from '../../../PythiaV3/components/Button/Button'
import SearchInputBlock from '../../../PythiaV3/pages/Complaints/components/SearchInputBlock/SearchInputBlock'
import {useFetchAssistFilesQuery} from '../../store/slices/assistFiles/apis/assistFiles'
import {IAssistFile} from '../../store/slices/assistFiles/interfaces/IAssistFile'
import AiSidebarProvider from '../AiActionsSidebarView/AiActionsProvider'
import {AiActionsSidebarView} from '../AiActionsSidebarView/AiActionsSidebarView'
import AssistFilesContentView from '../AssistFilesContentView/AssistFilesContentView'
import styles from './AssistFilesView.module.scss'

function AssistFilesView(): ReactElement {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm.trim())
  const {data, isLoading} = useFetchAssistFilesQuery({searchInput: debouncedSearchTerm})
  const [mappedData, setMappedData] = useState<IAssistFile[]>([])
  const [activeFile, setActiveFile] = useState<IAssistFile | undefined>(undefined)
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false)
  const [activeActionId, setActiveActionId] = useState<string | undefined>(undefined)
  const [activePrompt, setActivePrompt] = useState<string | undefined>(undefined)

  useEffect(() => {
    const mappedItems: IAssistFile[] =
      data?.items.map((item) => ({
        ...item,
        content: formatAndSanitizeContent(item.content),
      })) ?? []
    setMappedData(mappedItems)
    if (activeFile && data?.items?.some((item) => item.id === activeFile.id)) {
      return
    }
    setActiveFile(data?.items[0])
  }, [data])

  if (isLoading) {
    return <DotsLoader variant='centered' />
  }

  return (
    <AiSidebarProvider>
      <div className={styles.container}>
        <div className={styles.pageHeader}>
          <div className={styles.buttonContainer}>
            {activeFile && (
              <Button
                title='AI'
                variant={ButtonVariant.PRIMARY}
                icon={book}
                widthVariant={ButtonWidthVariant.AI}
                onClick={() => {
                  setSidebarVisible(true)
                }}
                disabled={!activeFile}
              />
            )}
          </div>
        </div>
        <div className={styles.content}>
          <SearchInputBlock
            activeIncident={activeFile}
            setActiveIncident={(incident) => {
              const item = data?.items.find((item) => incident?.id === item.id)
              setActiveFile(item)
            }}
            complaints={mappedData}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
          {data?.totalCount && data?.totalCount > 0 && (
            <AssistFilesContentView
              activeItem={activeFile || null}
              mode='file'
              activeActionId={activeActionId}
              activePrompt={activePrompt}
              onAiRequestComplete={() => {
                setActiveActionId(undefined)
                setActivePrompt(undefined)
              }}
            />
          )}
        </div>
        <ResizableSidebar
          storageKey='agedCareAssistSidebar'
          visible={sidebarVisible}
          onHide={() => setSidebarVisible(false)}
          position='right'
          style={{width: '70rem'}}
        >
          <AiActionsSidebarView
            statusId={activeFile?.statusId}
            onAction={(actionId) => {
              setActiveActionId(actionId)
              setActivePrompt(undefined)
              setSidebarVisible(false)
            }}
            onPrompt={(prompt) => {
              setActivePrompt(prompt)
              setActiveActionId(undefined)
              setSidebarVisible(false)
            }}
          />
        </ResizableSidebar>
      </div>
    </AiSidebarProvider>
  )
}

export default AssistFilesView
