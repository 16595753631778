import DomPurify from 'dompurify'
import {marked} from 'marked'
import {ReactElement, useEffect, useMemo, useRef, useState} from 'react'
import {Form} from 'react-bootstrap'
import 'react-quill/dist/quill.snow.css'
import avatar from 'src/app/common/assets/images/avatar1.png'
import {AssistService} from '../../../../../api/services/AssistService'
import {useCurrentUser} from '../../../../../queries/getCurrentUser'
import {ButtonVariant, ButtonWidthVariant} from '../../../../common/constants/enams'
import useLoader from '../../../../common/hooks/useLoader/useLoader'
import {useUpdateContentItemMutation} from '../../../../common/store/slices/contentItems/apis/contentItems'
import {IContentItem} from '../../../../common/store/slices/contentItems/interfaces/IContentItemsResponse'
import Button from '../../../PythiaV3/components/Button/Button'
import Subtitle from '../../../PythiaV3/components/Subtitle/Subtitle'
import {useFileContent} from '../../hooks/useFileContent'
import {
  useDeleteFileMutation,
  useUpdateFileMutation,
} from '../../store/slices/assistFiles/apis/assistFiles'
import {IAssistFile} from '../../store/slices/assistFiles/interfaces/IAssistFile'
import TextEditor, {Commands} from '../TextEditor/TextEditor'
import styles from './AssistFilesContentView.module.scss'
import {TabMenu, TabMenuTabChangeEvent} from 'primereact/tabmenu'
import {handleError, handleResponse} from 'src/api/helpers'
import {useNotifications} from 'src/app/common/hooks/useNotifications/useNotifications'
import TemplateDemo from '../FilesUpload/FilesUpload'

const defaultItem = '{"content":"", "context":""}'

interface CommonProps {
  activeActionId?: string | undefined
  activePrompt?: string | undefined
  onTextSelected?: (text: string) => void
  onAiRequestComplete?: () => void
}

interface FileProps {
  activeItem: IAssistFile | null
  mode: 'file'
}

interface TemplateProps {
  activeItem: IContentItem | null
  mode: 'template'
}

export type Props = (CommonProps & FileProps) | (CommonProps & TemplateProps)

const items = [
  {label: 'Content', icon: 'pi pi-code'},
  {label: 'Context', icon: 'pi pi-sliders-v'},
  {label: 'Files', icon: 'pi pi-file'},
]

export default function AssistFilesContentView({
  activeItem,
  mode,
  activeActionId,
  activePrompt,
  onTextSelected,
  onAiRequestComplete,
}: Props): ReactElement {
  const [deleteFile, {isLoading}] = useDeleteFileMutation()
  const [updateFile, {isLoading: isUpdatingFile}] = useUpdateFileMutation()
  const [updateContentItem, {isLoading: isUpdatingContentItem}] = useUpdateContentItemMutation()
  const fileContent = useFileContent(activeItem?.content || defaultItem)
  const [subject, setSubject] = useState(activeItem?.subject || '')
  const [content, setContent] = useState(fileContent.content)
  const [context, setContext] = useState(fileContent.content)
  const user = useCurrentUser()
  const isAdmin = user.data?.roles?.includes('Admin') ?? false
  const isReadonly = mode === 'template' && !isAdmin
  const textEditorRef = useRef<Commands>(null)
  const {loading: generatingPrompt, setLoading: setGeneratingPrompt} = useLoader()
  const [activeItemId, setActiveItemId] = useState<string | undefined>(undefined)
  const [isBusy, setIsBusy] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const {addNotification} = useNotifications()

  useEffect(() => {
    setSubject(activeItem?.subject || '')
    setContent(fileContent.content)
    setContext(fileContent.context)
  }, [activeItem?.id])

  useEffect(() => {
    const timerId = setTimeout(async () => {
      if (activeItemId !== activeItem?.id) {
        setActiveItemId(activeItem?.id)
        return
      }

      const fileContent = JSON.stringify({content, context})
      if (activeItem && !isReadonly) {
        mode === 'file'
          ? await updateFile({id: activeItem?.id, subject: subject, content: fileContent})
          : await updateContentItem({
              id: activeItem?.id,
              subject: subject,
              content: fileContent,
              contactId: activeItem.contactId,
              flagged: activeItem.flagged,
              highPriority: activeItem.highPriority,
            })
      }
    }, 500)

    return () => {
      clearTimeout(timerId)
    }
  }, [content, context, subject])

  useEffect(() => {
    if (activeActionId) {
      handleAiAction(undefined, activeActionId)
    }
  }, [activeActionId])

  useEffect(() => {
    if (activePrompt) {
      handleAiAction(activePrompt, undefined)
    }
  }, [activePrompt])

  useEffect(() => {
    const isBusy = isUpdatingFile || isUpdatingContentItem
    if (isBusy) {
      setIsBusy(true)
      return
    }
    setTimeout(() => {
      setIsBusy(isUpdatingFile || isUpdatingContentItem)
    }, 500)
  }, [isUpdatingFile, isUpdatingContentItem])

  const handleAiAction = async (prompt?: string, actionId?: string) => {
    if (!prompt && !actionId) {
      return
    }

    setGeneratingPrompt(true, 'Generating you action, this could take up to a few minutes')
    try {
      const contentToRequest = textEditorRef?.current?.getSelectedMarkdown?.() || content
      const fullPromptObject = {prompt, content: contentToRequest, context}
      const res = await AssistService.assistGerenatePrompt(
        JSON.stringify(fullPromptObject),
        actionId
      )
      if (res.success && textEditorRef?.current?.insertMarkdown) {
        const html = await marked.parse(res.result)
        const sanitizedHtml = DomPurify.sanitize(html, {
          ALLOWED_TAGS: [
            'p',
            'h1',
            'h2',
            'h3',
            'td',
            'tr',
            'table',
            'th',
            'pre',
            'blockquote',
            'b',
            'i',
            'u',
            's',
            'a',
            'code',
            'li',
            'img',
            'video',
          ],
          ALLOWED_ATTR: ['href', 'src', 'alt', 'title', 'width', 'height', 'style'],
        })
        textEditorRef.current.insertMarkdown(sanitizedHtml)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setGeneratingPrompt(false)
      onAiRequestComplete?.()
    }
  }

  const handleDelete = async () => {
    if (activeItem) {
      await deleteFile({id: activeItem.id})
        .unwrap()
        .then((response) =>
          handleResponse({response, addNotification, success: 'File deleted successfully'})
        )
        .then(() => {
          return Promise.reject('Intentional rejection')
        })
        .catch((error) => handleError({error, addNotification}))
    }
  }

  const handleTabChange = (e: TabMenuTabChangeEvent) => {
    setActiveIndex(e.index)
  }

  const viewMap: {[key: number]: JSX.Element} = useMemo(
    () => ({
      0: (
        <TextEditor
          fileId={activeItem?.id}
          ref={textEditorRef}
          text={content}
          setText={setContent}
          readonly={isReadonly || generatingPrompt}
          textSelected={(text) => {
            onTextSelected?.(text)
          }}
        />
      ),
      1: (
        <TextEditor text={context} setText={setContext} readonly={isReadonly || generatingPrompt} />
      ),
      2: <TemplateDemo />,
    }),
    [activeItem, isReadonly, generatingPrompt, content, context, onTextSelected]
  )

  return (
    <>
      <div className={styles.incidentBlock}>
        <div className={styles.incidentBlockInnerContainer}>
          <div className={styles.incidentBlockHeader}>
            <div className={styles.titleBlock}>
              <div className={styles.avatar}>
                <img src={avatar} alt='avatar' />
              </div>
              {mode === 'template' ? (
                <Subtitle text={activeItem?.subject || ''} />
              ) : (
                <Form.Control
                  type='text'
                  value={subject}
                  className={styles.titleInput}
                  onChange={({target}) => setSubject(target.value)}
                ></Form.Control>
              )}
            </div>
            <div className={styles.contextButtons}>
              {activeItem && (
                <Button
                  title='Delete'
                  variant={ButtonVariant.GRAY_TEXT}
                  widthVariant={ButtonWidthVariant.EXPLORE}
                  onClick={handleDelete}
                  isLoading={isLoading || generatingPrompt}
                />
              )}
              <i
                className='pi pi-spin pi-cog'
                style={{
                  color: '#a2acbc',
                  fontSize: '2rem',
                  transition: 'opacity 0.5s ease-in-out',
                  opacity: isBusy ? 1 : 0,
                }}
              ></i>
            </div>
          </div>
          <div className={styles.contentBlock}>
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={handleTabChange} />
            <div className={styles.content}>{viewMap[activeIndex]}</div>
          </div>
        </div>
      </div>
    </>
  )
}
