import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
// import {Dashboard} from '../pages/Dashboard copy/Dashboard'
import {Analytics} from '../pages/Analytics/Analytics'
// import {Categories} from '../pages/Configuration/Categories'
import {ExternalApplicationsList} from '../pages/Configuration/ExternalApplications'
import {Applications} from '../pages/Applications/Applications'
// import {DashboardsList} from '../pages/Configuration/Dashboards'
import {ExternalSoftwaresList} from '../pages/Configuration/ExternalSoftware'
import {SoftwareList} from '../pages/Softwares'
import {PowerBIReports} from '../pages/PowerBi'
import {PythiaV3} from '../pages/PythiaV3'
import {AgedCareAssist} from '../pages/AgedCareAssist'
import {NewConfiguration} from '../pages/Configuration/routes'
import {UserList} from '../pages/Configuration/Users/pages/userList'
import {PythiaConfiguration} from '../pages/Configuration/PythiaV2/pages/pythiaConfiguration'
import {RolesList} from '../pages/Configuration/Roles/pages/rolesList'
import {AskMe} from '../pages/AskMe'
import {AskMeConfiguration} from '../pages/Configuration/AskMe/pages'
import {Overview} from '../pages/Overview'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const SoftwareList = lazy(() => import('../pages/Softwares'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/agedcareai' />} />
        {/* Pages */}
        {/* <Route path='dashboard/:dashboardId' element={<Dashboard />} /> */}
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='dashboard/*'
          element={
            <SuspensedView>
              <Dashboard />
            </SuspensedView>
          }
        /> */}
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='data-analytics'
          element={
            <SuspensedView>
              <Analytics />
            </SuspensedView>
          }
        />
        <Route
          path='external-software'
          element={
            <SuspensedView>
              <SoftwareList />
            </SuspensedView>
          }
        />
        <Route path='configuration/user-management/*' element={<UserList />} />
        {/* <Route path='configuration/categories' element={<Categories />} /> */}
        <Route path='configuration/role-management/*' element={<RolesList />} />
        <Route path='configuration/ask-me/*' element={<AskMeConfiguration />} />

        {/* <Route path='configuration/external-applications' element={<ExternalApplicationsList />} /> */}
        {/* <Route path='configuration/external-software' element={<ExternalSoftwaresList />} /> */}
        {/* <Route path='configuration/dashboards' element={<DashboardsList />} /> */}
        <Route path='configuration/pythia/*' element={<PythiaConfiguration />} />
        <Route path='configuration/new/*' element={<NewConfiguration />} />
        <Route path='application/:id' element={<Applications />} />
        <Route path='data-warehouse/:id' element={<PowerBIReports />} />
        <Route path='agedcareai/*' element={<PythiaV3 />} />
        <Route path='askme/*' element={<AskMe />} />
        <Route path='assist/*' element={<AgedCareAssist />} />
        <Route path='overview/*' element={<Overview />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
